/* eslint-disable lines-around-directive */
/* eslint-disable function-paren-newline */
/* globals grecaptcha */
'use strict';

var formValidation = require('../components/formValidation');
var createErrorNotification = require('../components/errorNotification');

var recaptchaKey = $('*[data-recaptcha]').data('recaptcha');
var notifyme = require('../../util/notifyme');

module.exports = {
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            grecaptcha.ready(function () {
                grecaptcha.execute(recaptchaKey, { action: 'login' }).then(function (token) {
                    $.ajax({
                        url: url,
                        type: 'post',
                        dataType: 'json',
                        data: form.serialize() + '&token=' + token,
                        success: function (data) {
                            form.spinner().stop();
                            if (!data.success) {
                                formValidation(form, data);
                                $('form.login').trigger('login:error', data);
                            } else {
                                $('form.login').trigger('login:success', data);
                                window.location.href = data.redirectUrl;
                            }
                        },
                        error: function (data) {
                            if (data.responseJSON.redirectUrl) {
                                window.location.href = data.responseJSON.redirectUrl;
                            } else {
                                $('form.login').trigger('login:error', data);
                                form.spinner().stop();
                            }
                        },
                    });
                });
            });

            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);
            grecaptcha.ready(function () {
                grecaptcha.execute(recaptchaKey, { action: 'register' }).then(function (token) {
                    $.ajax({
                        url: url,
                        type: 'post',
                        dataType: 'json',
                        data: form.serialize() + '&token=' + token,
                        success: function (data) {
                            form.spinner().stop();
                            if (!data.success) {
                                $('form.registration').trigger('login:register:error', data);
                                formValidation(form, data);
                                if (data.errorMessage) {
                                    notifyme.setMessage(data.errorMessage, 'error', { autoClose: true });
                                }
                            } else {
                                $('form.registration').trigger('login:register:success', data);
                                window.location.href = data.redirectUrl;
                            }
                        },
                        error: function (err) {
                            if (err.responseJSON.redirectUrl) {
                                window.location.href = err.responseJSON.redirectUrl;
                            } else {
                                createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                            }

                            form.spinner().stop();
                        },
                    });
                });
            });

            return false;
        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn').empty()
                                .html('<a href="'
                                    + data.returnUrl
                                    + '" class="btn btn-primary btn-block">'
                                    + data.buttonText + '</a>'
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                },
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },
};
